// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const signInConfig = {
  WEB_PROTOCOL:'https://',
  // CHANGE ACCORDINGLY FOR EVERY PROJECT
  // **** START ****
  // DOMAIN_NAME: 'hht-web-dev.auth.ap-southeast-1.amazoncognito.com',
  // CLIENT_ID: 'sk05k323vmeiq342m96bfk75h',
  // RESPONSE_TYPE: 'token',  // or 'token', note that REFRESH token will only be generated when the responseType is code
  // REDIRECT_SIGN_IN: 'http://localhost:4200/login',
  // REDIRECT_SIGN_OUT: 'http://localhost:4200',
  // SCOPE: ['email', 'openid'],
  // **** END ****

  DOMAIN_NAME: 'hht-web-test-newrover.auth.ap-southeast-1.amazoncognito.com',
  CLIENT_ID: '70d7eu5626sfduej8tv8hvvo32',

  RESPONSE_TYPE: 'token',  // or 'token', note that REFRESH token will only be generated when the responseType is code
  // REDIRECT_SIGN_IN: 'https://d9zjm0rzjdh9y.cloudfront.net/login',
  // REDIRECT_SIGN_OUT: 'https://d9zjm0rzjdh9y.cloudfront.net',
  REDIRECT_SIGN_IN: 'https://portal-hhtdbupgrade.meco-soldel.com/login',
  REDIRECT_SIGN_OUT: 'https://portal-hhtdbupgrade.meco-soldel.com',

  // REDIRECT_SIGN_IN: 'https://d1pz1nsaci2qk9.cloudfront.net/login',
  // REDIRECT_SIGN_OUT: 'https://d1pz1nsaci2qk9.cloudfront.net',

  // REDIRECT_SIGN_IN: 'http://localhost:4200/login',
  // REDIRECT_SIGN_OUT: 'http://localhost:4200',

  SCOPE: ['email', 'openid','aws.cognito.signin.user.admin'],
}

export const environment = {
  production: false,

  app_version : 'v2024.0.0.1',
  // CHANGE ACCORDINGLY FOR EVERY PROJECT
  // **** START ****
  homepage: '/sample',
  hostURL: 'https://api-hhtdbupgrade.meco-soldel.com/dev',
  
  // **** END ****

  // AMPLIFY environments
  domainName: `${signInConfig.DOMAIN_NAME}`,
  clientId: `${signInConfig.CLIENT_ID}`,
  responseType: `${signInConfig.RESPONSE_TYPE}`,
  redirectUri: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignIn: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignOut: `${signInConfig.REDIRECT_SIGN_OUT}`,
  scope: signInConfig.SCOPE,
  signIn: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/login?client_id=${signInConfig.CLIENT_ID}&response_type=${signInConfig.RESPONSE_TYPE}&scope=email+openid&redirect_uri=${signInConfig.REDIRECT_SIGN_IN}`,
  signOut: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/logout?client_id=${signInConfig.CLIENT_ID}&logout_uri=${signInConfig.REDIRECT_SIGN_OUT}`
};