// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-update-msg {
  top: 10px;
  right: 18px;
  position: absolute;
  width: 280px;
  height: 105px;
  background: var(--unnamed-color-2c906b) 0% 0% no-repeat padding-box;
  background: #2C906B 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1019607843);
  border-radius: 6px;
  opacity: 1;
  cursor: pointer;
}

.msg-title {
  text-align: left;
  font: normal normal bold 16px/19px Helvetica Neue, sans-serif;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  padding-left: 20px;
  padding-top: 20px;
}

.msg-text {
  text-align: left;
  font: normal normal normal 16px/18px Helvetica Neue, sans-serif;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/core/hht/master-meter-data/master-meter-data.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mEAAA;EACA,+CAAA;EACA,oDAAA;EACA,kBAAA;EACA,UAAA;EACA,eAAA;AACJ;;AAEA;EAEI,gBAAA;EACA,6DAAA;EACA,mBAAA;EACA,cAAA;EACA,UAAA;EACA,kBAAA;EACA,iBAAA;AAAJ;;AAGA;EAEI,gBAAA;EACA,+DAAA;EACA,mBAAA;EACA,cAAA;EACA,UAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,iBAAA;AADJ","sourcesContent":[".location-update-msg{\n    top: 10px;\n    right: 18px;\n    position: absolute;\n    width: 280px;\n    height: 105px;\n    background: var(--unnamed-color-2c906b) 0% 0% no-repeat padding-box;\n    background: #2C906B 0% 0% no-repeat padding-box;\n    box-shadow: 0px 5px 10px #0000001A;\n    border-radius: 6px;\n    opacity: 1;\n    cursor: pointer;\n}\n\n.msg-title{\n    // color: var(--unnamed-color-ffffff);\n    text-align: left;\n    font: normal normal bold 16px/19px Helvetica Neue,sans-serif;\n    letter-spacing: 0px;\n    color: #FFFFFF;\n    opacity: 1;\n    padding-left: 20px;\n    padding-top: 20px;\n}\n\n.msg-text{\n    // color: var(--unnamed-color-ffffff);\n    text-align: left;\n    font: normal normal normal 16px/18px Helvetica Neue,sans-serif;\n    letter-spacing: 0px;\n    color: #FFFFFF;\n    opacity: 1;\n    padding-left: 20px;\n    padding-bottom: 20px;\n    padding-right: 20px;\n    padding-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
