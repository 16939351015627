import { Component, OnInit,Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { Hub, Auth } from 'aws-amplify';

// import { Amplify } from '@aws-amplify/core';
// import Auth_ from '@aws-amplify/auth';

// Amplify.configure({
//   Auth_: {
//     //identityPoolId: 'ap-southeast-1_OxfWFxzuX',
//     region : 'ap-southeast-1',
//     // dev -'6gbvhjbkn58j5scluj8p8999jk'
//     // uat -'6u1nno2r4c049uq3mmalimnv7f'
//     // prod - '7rgikb252bpacfa0nrplrjcrve'
//     userPoolId: '7rgikb252bpacfa0nrplrjcrve',
    
//     userPoolWebClientId: 'hhtwebf47570b1_app_clientWeb',
//     authenticationFlowType: 'USER_PASSWORD_AUTH'
//   }
// });


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  cognitoUserName:string;
  cognitoEmail:string;
  cognitoDetails: any = [];
  constructor(private activatedRoute: ActivatedRoute, private hhtApiService: HhtApiService,)  { }
  isLoading: boolean = false;
  ChangePass: boolean = true;
  hasRepetitiveChar: number = 0;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
    })
  }
  Password1: string = '';
  Password2: string = ''; 
  ConfirmationCode: string = '';
  Password0: string = '';
  AccessToken: string = '';

  charRepeats(str: string): boolean {
    let count = 0;
    for (let i = 0; i < str.length; i++) {
      if (str.indexOf(str[i]) !== str.lastIndexOf(str[i])) {
        count++;
      }
    }
    return count < 3;
  }
  
  onKey(event: any) { 
    //----
    var length = document.getElementById("Length");
    if (this.Password1.length >= 14) {
      //alert('Password Length should be 14')
      length.classList.remove("passwordCriteriaInvalid");
      length.classList.add("passwordCriteriaValid");
    }
    else {
      length.classList.remove("passwordCriteriaValid");
      length.classList.add("passwordCriteriaInvalid");
    }
    //----
    var char = document.getElementById("Char");
    var pattern = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    );
    if (pattern.test(this.Password1)){
      char.classList.remove("passwordCriteriaInvalid");
      char.classList.add("passwordCriteriaValid");
    }
    else {
      char.classList.remove("passwordCriteriaValid");
      char.classList.add("passwordCriteriaInvalid");
    }
    //----
    var repeatitive = document.getElementById("Repeatitive");
    var splittedPassword = this.Password1.split("")
    var i
    var count = 0
    var hasRepetitiveChar = 0
    for (i = 0; i < splittedPassword.length; i++) {
      if (splittedPassword[i] == splittedPassword[i-1]) {
        count++
        /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
        if (count >= 3) {
          hasRepetitiveChar = 1
          break;
        }
      }
      else {count = 0}
    }
    if (hasRepetitiveChar != 1){
      repeatitive.classList.remove("passwordCriteriaInvalid");
      repeatitive.classList.add("passwordCriteriaValid");
    }
    else {
      /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
      repeatitive.classList.remove("passwordCriteriaValid");
      /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
      repeatitive.classList.add("passwordCriteriaInvalid");
    }
    //----
    var email = document.getElementById("Email");
    /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
    Auth.currentSession().then( data => {
      this.cognitoEmail = data.getIdToken().payload.email;
      this.cognitoUserName = data.getIdToken().payload['cognito:username'];
      if(this.Password1 == this.cognitoUserName){
        email.classList.remove("passwordCriteriaValid");
        email.classList.add("passwordCriteriaInvalid");
      }
      else {
        email.classList.remove("passwordCriteriaInvalid");
        email.classList.add("passwordCriteriaValid");
      }
    })
  }
  
  checkForRepetitiveChars() {
    const password = this.Password1;
    let count = 1;
    let hasRepetitiveChar = 0;
  
    for (let i = 1; i < password.length; i++) {
      if (password[i] === password[i - 1]) {
        count++;
        if (count >= 3) {
          hasRepetitiveChar = 1;
          break;
        }
      } else {
        /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
        count = 1; // Reset count if characters are not repetitive
      }
    }
  
    this.hasRepetitiveChar = hasRepetitiveChar;
  }

  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  savePassword(){
    
    Auth.currentSession().then( data => {
      this.cognitoEmail = data.getIdToken().payload.email;
      this.cognitoUserName = data.getIdToken().payload['cognito:username'];

      //console.log("cognito username:",this.cognitoUserName)
      //console.log("Verify Password1: " , this.Password1)
      //console.log("Verify Password2: " , this.Password2)
      //console.log("Previous Password: " , this.Password0)
      
    
      if(this.Password1 != this.Password2){
        alert('Password not match')
      }
      else{
        console.log("PasswordLenghth",this.Password1.length)
        if (this.Password1.length <= 13) {
          alert('Password Length should be 14')
        }
        else {
          if (this.Password1.length >= 21) {
            alert('Your new password is too long. Password max. length is 20')
          }
          else{
            var pattern = new RegExp(
              "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
            ); 
          if (!pattern.test(this.Password1)){
            alert('Password must have upper case, lower case, special and numeric characters.')
          }
          else {
            if(this.Password1 == this.cognitoUserName){
              alert('Password shall not be equal to email')
            }else{
              var splittedPassword = this.Password1.split("")
              var i
              var count = 0
              var hasRepetitiveChar = 0
              for (i = 0; i < splittedPassword.length; i++) {
                if (splittedPassword[i] == splittedPassword[i-1]) {
                  count++
                  if (count >= 3) {
                    console.log(count)
                    hasRepetitiveChar = 1
                    break;
                  }
                }
                else {count = 0}
              }
              if (hasRepetitiveChar == 1){
                alert("Password shall not have repetitive character")
              }
              else{
                const body = {
                  password: this.Password1,
                  userName: this.cognitoUserName,
                  email: this.cognitoEmail
                }
                console.log(body)
                if(confirm("Are you sure to change password?")) {
                  this.hhtApiService.validateInsertPassword(body).subscribe(res => {
                    console.log(res['body'].data[0]['ErrCode'])
                    if (res['body'].data[0]['ErrCode'] == 1) {
                      alert(res['body'].data[0]['ErrMsg'] )
                    }
                    else {
                        
                        this.isLoading = true;
                        this.ChangePass = false;
                        Auth.signIn(this.cognitoUserName,this.Password0).then(user =>{
                          console.log('Signed in - Try changing Password')
                          Auth.currentAuthenticatedUser()
                            .then(user => {
                               //return Auth_.changePassword(user, this.Password0, this.Password1);
                               Auth.changePassword(user, this.Password0, this.Password1).then(users => alert('Password successfully changed'))
                               this.isLoading = false;
                               this.ChangePass = true;
                               this.Password0 = '';
                               this.Password1 = '';
                               this.Password2 = '';
                            })
                            .then(data => 
                              {
                                console.log(data['message'])
                                //alert(data)
                              }
                              ) //console.log(data)
                            .catch(err => {
                              console.log(err)
                              //alert(err)
                            }); //console.log(err)
                        })
                        .catch(err => {
                          console.log(err['message'])
                          this.isLoading = false;
                          this.ChangePass = true;
                          alert(err['message'])
                          
                        }); //console.log(err)

                        
                    }
                  });
                }
              }
              //console.log(charRepeats(this.Password1))
              }
            }
          }
        }
      }
    }
  )};

}

  
  
/* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  var charRepeats = function(str) {
    var count = 0
    for (var i=0; i<str.length; i++) {
      if ( str.indexOf(str[i]) !== str.lastIndexOf(str[i]) ) {
        count++
      }
    }
    if (count >= 3) {
      return false;
    }

  return true;
  }

  

  