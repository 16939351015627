
import { Routes } from '@angular/router';
import { HhtMainComponent } from './hht-main/hht-main.component';
import { MasterMeterDataComponent } from './master-meter-data/master-meter-data.component';
import { ItineraryComponent } from './itinerary/itinerary.component';
import { ItineraryListComponent } from './itinerary-list/itinerary-list.component';
import { ChangePasswordComponent } from './change-password/change-password.component';


export const hhtRoutes: Routes = [

  {
    path: 'hhtmain',
    component: HhtMainComponent
  },
  {
    path: 'itinerary-list',
    component: ItineraryListComponent
  },
  {
    path: 'itinerary-list/selected-itinerary',
    component: ItineraryComponent
  },
  {
    path: 'meter-list',
    component: MasterMeterDataComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  }

]
