import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { JwtHelperService } from "@auth0/angular-jwt";
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  myVariable: any;
  constructor() { }

  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  public isAuthenticated(): Promise<boolean> {
    return this.retrieveIdToken()
      .then(token => {
        // console.log(token);
        const helper = new JwtHelperService();
        return token !== null && !helper.isTokenExpired(token);
      })
      .catch(() => {
        console.log("no session")
        return false;
      });
  }

  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  public retrieveIdToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(data => {
          if (data.isValid()){
            resolve(data.getIdToken().getJwtToken());
          }
          else{
            reject({message: "Session Invalid."});
          }
        })
        .catch(err => reject({message: "Session Invalid."}));
    });
  }

  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  isVerified(){
    return from(Auth.currentAuthenticatedUser())
  }

  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  public signouttest(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (Auth.signOut({global: true}) ){
        resolve(true)
      }{
        reject(true)
      }
    })
  }
}