import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Auth } from 'aws-amplify';
import { environment } from '@environments/environment';
import { StoreService } from '@app/shared/services/store.service';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: 'hht/itinerary-list',     
    title: 'Itineraries',         
    icon:'../../assets/img/icons/icon-itinerary.svg',       
    class: '' 
  },
  { path: 'hht/meter-list',       
    title: 'Master Meter Data',           
    icon:'../../assets/img/icons/icon-mmd.png',  
    class: '' 
  }
];


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']

})
export class AdminLayoutComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private storeService: StoreService

  ) { }

  ngOnInit() { 
  }


  itenerariesOnClick() {
    console.log('itenerariesOnClick')
    this.router.navigate(['hht/i'])
  }

  masterMeterDataOnClick() {
    this.router.navigate(['hht/mmd'])
  }

  itenerariesListOnClick(){
    this.router.navigate(['hht/il'])
  }

  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  onLogOut() {
    Auth.signOut()
    localStorage.clear();
    window.location.replace(environment.signOut);
    this.storeService.clear();
  }
}
