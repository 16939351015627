import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilter'
})
export class TableFilterPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

//   transform(list: any[], value: string) {
//     return value ? list.filter(item => item.month === value || item.YEAR === value) : list;
//  }

transform(list: any[], filterValue: string): any[] {
  if (!filterValue) {
    return list;
  }
  return list.filter(item => item.year === filterValue);
}
}
