import { Injectable } from '@angular/core';
import { HHTProviderService } from './http/http-provider.service';
import { HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HhtApiService {


  //IMPORTANT import service on Providers list on your module.
  constructor(private httpProvider: HHTProviderService) { }

  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  public getAllItineraries(searchString: any, limit: number, offset: number, year: any, month: any) {
    return this.httpProvider.sendGetRequest(`/get_all_itineraries?&search_field=${searchString}&limit=${limit}&offset=${offset}&year=${year}&month=${month}`, new HttpHeaders, new HttpParams)
  }

  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  public getAllItinerariesCount(userName: any, searchString: any, limit: number, offset: number, year: any, month: any) {
    return this.httpProvider.sendGetRequest(`/get_all_itineraries_count?userName=${userName}&search_field=${searchString}&limit=${limit}&offset=${offset}&year=${year}&month=${month}`, new HttpHeaders, new HttpParams)
  }
  
  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  public getAllMeters(userName: any,searchString: any, limit: number, offset: number) {
    return this.httpProvider.sendGetRequest(`/get_all_meter?userName=${userName}&search_field=${searchString}&limit=${limit}&offset=${offset}`, new HttpHeaders, new HttpParams)
  }

  // public getItineraryById(itineraryNumber:any, readings:any, device_code:any, center_code:any){
  //   return this.httpProvider.sendGetRequest(`/get_itineraries_by_id?itineraryNumber=${itineraryNumber}&readings=${readings}&device_code=${device_code}&center_code=${center_code}`, new HttpHeaders, new HttpParams);
  // }

  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  public getItineraryById(itineraryNumber:any, readings:any, device_code:any, center_code:any, data_filter:any, month:any, year:any){
    return this.httpProvider.sendGetRequest(`/get_itineraries_by_id?itineraryNumber=${itineraryNumber}&readings=${readings}&device_code=${device_code}&center_code=${center_code}&data_filter=${data_filter}&month=${month}&year=${year}`, new HttpHeaders, new HttpParams);
  }

  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  public getImage(meter_number:any, consumption_type:any, actual_reading_date:any){
    return this.httpProvider.sendGetRequest(`/get_file_presigned_url?meter_number=${meter_number}&consumption_type=${consumption_type}&actual_reading_date=${actual_reading_date}`, new HttpHeaders, new HttpParams);
  }
  
  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  public updateMeterLocation(body:any){
    return this.httpProvider.sendPostRequest('/update_meter_location', body, new HttpHeaders, new HttpParams);
  }

  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  public getUserAccountInfo(user_name:any){
    return this.httpProvider.sendGetRequest(`/get_user_account_Info?user_name=${user_name}`, new HttpHeaders, new HttpParams);
  }

  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  public validateInsertPassword(body:any){
    return this.httpProvider.sendPostRequest('/validate_Insert_Password', body, new HttpHeaders, new HttpParams);
  }

  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  public cognitoCognitoChangePassword(userName:any){
    return this.httpProvider.sendGetRequest(`/cognito_forgot_password?userName=${userName}`, new HttpHeaders, new HttpParams);
  }

  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  public cognitoCognitoConfirmChangePassword(userName:any, confirmationCode:any, password:any, prevPassword: any){
    return this.httpProvider.sendGetRequest(`/cognito_confirm_forgot_password?userName=${userName}&confirmationCode=${confirmationCode}&password=${password}&password=${prevPassword}`, new HttpHeaders, new HttpParams);
  }

 
  /* istanbul ignore next: "details why you chose to ignore this line for code coverage" */
  public cognitoChangePassword(userName:any,prevPassword:any,newPassword:any){
    return this.httpProvider.sendGetRequest(`/cognito_change_password?userName=${userName}&prevPassword=${prevPassword}&newPassword=${newPassword}`, new HttpHeaders, new HttpParams);
  }

}
